<template>
  <section class="callback-payment">
    <div class="card">
      <div class="img-container" style="margin-left: 15px" v-if="status == 10">
        <img src="@/assets/images/wallet/success.png" />
        <i class="bx bxs-check-square bx-tada success"></i>
      </div>
      <div class="img-container" v-else>
        <img src="@/assets/images/wallet/error.png" />
        <i class="bx bxs-error bx-tada error"></i>
      </div>
      <h2 class="success" v-if="status == 10">Transaction Success</h2>
      <h2 class="error" v-else>Transaction Failed</h2>
      <h5 v-if="!haveQuery">No Payment</h5>
      <div class="informations" v-else>
        <h3 class="message Q-font">
          {{ message }}
        </h3>
        <h3 class="status Q-font">
          Transaction {{ status == 10 ? "paid" : "failed" }} with
          <span class="Q-font">{{ statusCovertor(status) }}</span> status
        </h3>
        <h4 class="Q-font">
          Oreder Id: <span class="Q-font">{{ orderId }}</span>
        </h4>
      </div>
      <button class="btn" @click="$router.replace({name: 'wallets'})" >
        Back to wallets page
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      haveQuery: false,
      status: "",
      message: "",
      orderId: "",
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      let orderId = this.$route.query.orderId;
      let status = this.$route.query.status;
      let message = this.$route.query.message;

      //
      if (orderId && status && message) {
        this.haveQuery = true;
        this.orderId = orderId;
        this.status = status;
        this.message = message;
      }
    },
    // Status convertur for show in payment status
    statusCovertor(status) {
      if (status == 1) return "Expired";
      if (status == 2) return "Canceled";
      if (status == 10) return "Success";
    },
  },
};
</script>

<style lang="scss" scoped>
.callback-payment {
  background: url("../../../assets/images/wallet/pay-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    width: 95%;
    max-width: 450px;
    height: 80vh;
    border-radius: 4px;
    padding: 0px 20px 20px 20px;
    background: rgb(255, 255, 255);
    box-shadow: 5px 5px 12px 2px rgba(11, 11, 11, 0.856);
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-container {
      width: 70%;
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
      }
      i {
        position: absolute;
        left: 0;
        bottom: 5%;
        font-size: 100px;
      }
    }
    .informations {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color3);
    }
  }
  .btn{
    width: 80%;
    padding: 10px;
    margin-top: auto;
    border-radius: 3px;
    color: white;
    background:#229861;
  }
}
.success {
  color: #229861;
}
.error {
  color: #da384b;
}
</style>
